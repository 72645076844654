@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,300&family=Lato:wght@300;400;700;900&family=Montserrat:wght@200;300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,300&family=Lato:wght@100&family=Montserrat:wght@200;300;400;600;700;800&display=swap");

.banner-container {
  background: transparent linear-gradient(271deg, #00ceff5c 0%, #00569a 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
}
.card-container {
  box-shadow: 0px 0px 6px #0000001c;
}
.banner_gradient_container {
  background: #00bae8 0% 0% no-repeat padding-box;
  opacity: 0.8;
}

.card_content_container:hover > div > div {
  background-color: white;
}

.card_content_container:hover > div > a {
  background-color: white;
}

.card_content_container:hover > div > a > svg {
  filter: brightness(0) saturate(100%) invert(17%) sepia(98%) saturate(2232%)
    hue-rotate(191deg) brightness(96%) contrast(101%);
}

#scroll_button {
  /* clip-path: inset(0 0 0 0);
  transform: rotate(145deg); */
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

}
/* .icon_container {
  transform: rotate(212deg);
} */

.button_slide {
  box-shadow: inset 0 0 0 0 #00569a;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
.slide_right:hover {
  box-shadow: inset 400px 0 0 0 #00569a;
}
.dark_blue {
  clip-path: polygon(0 28%, 100% 0, 100% 100%, 0 100%);
}
.light_blue {
  clip-path: polygon(0 28%, 100% 0, 100% 100%, 0 100%);
  width: 90%;
  top: 12px;
  right: 12px;
}



.accordion_icon {
  transform: rotate(90deg);
  width: 10px;
}

.accordion_icon_open {
  transform: rotate(270deg);
  width: 10px;
}

.card_content_container:hover > div > div > svg {
  filter: brightness(0) saturate(100%) invert(17%) sepia(98%) saturate(2232%)
    hue-rotate(191deg) brightness(96%) contrast(101%);
}
.content_wrapper {
  box-shadow: 0px 1px 6px #00000029;
  padding: 1rem 0rem;
}
.image-card {
  clip-path: polygon(
    0 0,
    100% 0,
    100% 20%,
    100% 100%,
    72% 96%,
    22% 86%,
    0 100%,
    0% 20%
  );
}
.iframe-map {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.career-input-error {
  background-color: #ff3b583f !important;
  color: black;
}
.ant-drawer-header-title {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 320px) {
  .ant-drawer-content-wrapper {
    width: 320px !important;
  }
  .light_blue {
    clip-path: polygon(0 28%, 100% 0, 100% 100%, 0 100%);
    width: 90%;
    top: 5px;
    right: 5px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
