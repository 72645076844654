.App {
  text-align: center;
  /* font-family: ; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
  @apply scroll-smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.solutions-dropdown {
  @apply relative inline-block h-[100%] cursor-pointer;
}

.dropdown-content {
  @apply hidden absolute gap-4 bg-transparent z-30  w-max max-w-[300px] rounded;
}

.dropdown-content a,
.products-dropdown {
  @apply block text-left font-montserrat bg-white text-base font-medium py-3 px-4;
  text-decoration: none;
}
.solutions-dropdown:hover .dropdown-content {
  position: absolute;
  z-index: 30;
  display: none;
  width: max-content;
  max-width: 300px;
  gap: 1rem;
  border-radius: 0.25rem;
  background-color: transparent;
  padding-top: 2.9rem;
  /* margin-top: 3rem; */
  /* box-shadow: 0px 0px 5px 5px rgb(211, 211, 211); */
}

.dropdown-content a:hover,
.products-dropdown:hover {
  background-color: #66717A;
  color: white;
}

.solutions-dropdown:hover .dropdown-content {
  @apply block;
}
.solutions-dropdown:hover > p > svg  {
  filter: brightness(0) saturate(100%) invert(54%) sepia(20%) saturate(5030%) hue-rotate(155deg) brightness(99%) contrast(108%);  
  transform: rotate(180deg);


}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
